import './Sports.css';

function Physics() {
  return (
    <div className="Sports">
      <div className='Sports-hero'>
        <div className='Sports-heroCover'>
          <h1>Lamine Yamal the new legend.</h1>
        </div>
      </div>
    </div>
  );
}

export default Physics;